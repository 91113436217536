import React, { useCallback } from "react";
import { CtaButton } from "../../ReusableComponents/Buttons";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useAppDisptach, useAppReducer, useS } from "../../context";
import {
  CHANGE_MEMBERSHIP_TYPE,
  CHANGE_PERSON_MOBILE,
  CHANGE_PERSON_NAME,
  CHANGE_PERSON_DOB,
  CHANGE_PERSON_PINCODE,
  CHANGE_PERSON_GENDER,
  membershipTypes,
} from "../../context/Constants";
import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";

const AddGiftPage = ({ onNextStep }) => {
  const {
    personGender,
    personName,
    personPincode,
    personDOB,
    membershipType,
    personMobile,
  } = useAppReducer();

  const dispatch = useAppDisptach();

  const [params, setSearchParams] = useSearchParams();
  const typeOfMembership = params.get("type");

  const onChangePincode = useCallback(({ target }) => {
    console.log({ aaa: target.value });

    if (
      target.value == "" ||
      (target.value != null && target.value.length <= 8)
    ) {
      dispatch({ type: CHANGE_PERSON_PINCODE, value: target.value });
    }
  }, []);

  const onMembershipChange = useCallback((event) => {
    setSearchParams({ type: event.target.value, for: params.get("for") });
  }, []);

  const onChangeDOB = useCallback(({ target }) => {
    dispatch({ type: CHANGE_PERSON_DOB, value: target.value });
  }, []);

  const onChangePersonNumber = useCallback(
    (value) => {
      if (value && value.length <= 13) {
        dispatch({ type: CHANGE_PERSON_MOBILE, value });
      } else if (!value) {
        dispatch({ type: CHANGE_PERSON_MOBILE, value });
      } else if (personMobile.length === 13) {
        console.log({ personMobile });

        dispatch({ type: CHANGE_PERSON_MOBILE, value: personMobile });
      }
    },
    [personMobile]
  );

  const onPersonNameChange = useCallback((event) => {
    dispatch({ type: CHANGE_PERSON_NAME, value: event.target.value });
  }, []);

  const onGenderChange = useCallback(({ target }) => {
    dispatch({ type: CHANGE_PERSON_GENDER, value: target.value });
  }, []);

  const isInvalid =
    isEmpty(personName) ||
    (isEmpty(personMobile) && personMobile?.length != 13) ||
    isEmpty(personDOB) ||
    isEmpty(personGender);

  return (
    <div className="form__wrap form__wrap--gridWidth">
      <h2 className="form__title">
        <span>Add Gift person Details</span>
      </h2>
      <div className="form__fields ">
        <div className="form__control">
          <label className="lbl" htmlFor="selectMembership">
            Select Membership
          </label>
          <div className="input">
            <select value={typeOfMembership} onChange={onMembershipChange}>
              {membershipTypes.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form__control">
          <label className="lbl" htmlFor="fullName">
            Full Name:
          </label>
          <div className="input">
            <input
              autoComplete="off"
              onChange={onPersonNameChange}
              type="text"
              value={personName}
              id="fullName"
              name="fullName"
              placeholder=""
              required=""
            />
          </div>
        </div>

        <div className="form__control">
          <label className="lbl" htmlFor="phoneNumber">
            Enter Mobile Number:
          </label>
          <div className="input">
            <PhoneInput
              placeholder="Enter phone number"
              value={personMobile}
              international={true}
              onChange={onChangePersonNumber}
              defaultCountry="IN"
              limitMaxLength={true}
              error={
                personMobile
                  ? isPossiblePhoneNumber(personMobile)
                    ? undefined
                    : "Invalid phone number"
                  : "Phone number required"
              }
            />
          </div>
        </div>
        <div className="form__control">
          <label className="lbl" htmlFor="dob">
            Date of Birth
          </label>
          <div className="input">
            <input
              autoComplete="off"
              max="2000-01-01"
              min="1950-01-01"
              type="date"
              id="dob"
              name="dob"
              placeholder=""
              value={personDOB}
              required=""
              onChange={onChangeDOB}
            />
          </div>
        </div>
        <div className="form__control">
          <label className="lbl" htmlFor="gender">
            Gender
          </label>
          <div className="radioBtn__wrap">
            <div className="radioBtn">
              <input
                autoComplete="off"
                type="radio"
                checked={personGender === "Male"}
                id="male"
                value="Male"
                name="gender"
                onChange={onGenderChange}
              />
              <label htmlFor="male">Male</label>
            </div>
            <div className="radioBtn">
              <input
                autoComplete="off"
                type="radio"
                checked={personGender === "Female"}
                id="female"
                value="Female"
                name="gender"
                onChange={onGenderChange}
              />
              <label htmlFor="female">Female</label>
            </div>
            <div className="radioBtn">
              <input
                autoComplete="off"
                type="radio"
                checked={personGender === "Other"}
                id="other"
                value="Other"
                name="gender"
                onChange={onGenderChange}
              />
              <label htmlFor="other">Other</label>
            </div>
          </div>
        </div>
        <div className="form__control">
          <label className="lbl" htmlFor="pincode">
            Pincode
          </label>
          <div className="input">
            <input
              autoComplete="off"
              onChange={onChangePincode}
              value={personPincode}
              type="number"
              id="pincode"
              name="pincode"
              placeholder=""
              required
              maxLength={8}
            />
          </div>
        </div>
      </div>
      <div className="form__control">
        <div className="form__cta">
          <CtaButton
            btnCls="btn btn__primary"
            onClickProp={onNextStep}
            iconCls=""
            btnText="Submit"
            btnState={isInvalid}
          />
        </div>
      </div>
    </div>
  );
};

export default AddGiftPage;
