import React, { useState, useRef, useCallback } from "react";
import { CtaButton } from "../../ReusableComponents/Buttons";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import ResendOtp from "./ResendOtp";
import { useAppDisptach, useAppReducer } from "../../context";
import axios from "axios";
import {
  CHANGE_NAME,
  CHANGE_EMAIL,
  CHANGE_COMPANY_NAME,
} from "../../context/Constants";
import { CHANGE_MOBILE_NO } from "../../context/Constants";
import { useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";

const VerifyYourself = ({ onNextStep }) => {
  const [loading, setLoading] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const [otp, setOTP] = useState("");
  const [invalidOTP, setInvalidOTP] = useState(false);
  const dispatch = useAppDisptach();
  const { gifter } = useAppReducer();
  const { name, mobile_no } = gifter;
  const otpRef = useRef(null);
  const [params, setSearchParams] = useSearchParams();
  const forWho = params.get("for");

  const onChangeNumber = useCallback(
    (value) => {
      if (value && value.length <= 13) {
        dispatch({ type: CHANGE_MOBILE_NO, value });
      } else if (!value) {
        dispatch({ type: CHANGE_MOBILE_NO, value: "" });
      } else if (mobile_no.length === 13) {
      }
    },
    [mobile_no]
  );

  const onSendOTP = useCallback(() => {
    setShowOtpField(true);
    const requestOtp = async () => {
      try {
        const number = mobile_no.replace("+", "");
        const { data } = await axios.get(
          `https://thesmilingsouls.com/custom/index.php/api/v1/user/verify-mobile?mobile_no=${number}`
        );
      } catch (error) {}
      otpRef.current.focus();
      setLoading(false);
    };
    if (!loading) {
      setLoading(true);
      requestOtp();
    }
  }, [mobile_no, loading]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onVerifyOTP = useCallback(() => {
    if (otp === null || otp === "" || otp.length !== 4) {
      setInvalidOTP(true);
      otpRef.current.focus();
      return;
    }

    const onVerify = async () => {
      try {
        const number = mobile_no.replace("+", "");
        const { data } = await axios.get(
          `https://thesmilingsouls.com/custom/index.php/api/v1/user/verify-otp?mobile_no=${number}&otp=${otp}`
        );
        console.log(data);
        if (data?.code == 200) {
          onNextStep();
        } else {
          setInvalidOTP(true);
          otpRef.current.focus();
        }
      } catch (error) {}
      setLoading(false);
    };

    if (!loading) {
      setLoading(true);
      onVerify();
    }
  }, [mobile_no, loading, otp]);

  const onOTPChange = useCallback((event) => {
    const { value = "" } = event.target;
    if (value != null && value.length <= 4) {
      setOTP(value);
    }
    setInvalidOTP(false);
  }, []);

  const onChangeName = useCallback((event) => {
    dispatch({ type: CHANGE_NAME, value: event.target.value });
    event.preventDefault();
  }, []);

  const onChangeComapnyName = useCallback((event) => {
    dispatch({ type: CHANGE_COMPANY_NAME, value: event.target.value });
    event.preventDefault();
  }, []);

  const onChangeEmail = useCallback((event) => {
    dispatch({ type: CHANGE_EMAIL, value: event.target.value });
    event.preventDefault();
  }, []);

  const onEditNumber = useCallback(() => {
    setOTP("");
    setShowOtpField(false);
  }, []);

  const isValid = mobile_no.length === 13 && name.length > 0;

  return (
    <div className="form__wrap">
      <h2 className="form__title">
        <span>Verify Yourself</span>
      </h2>
      <div className="form__fields form__fields--smWidth">
        <div className="form__control">
          <label className="lbl" htmlFor="name">
            Full Name:
          </label>
          <div className="input">
            <input
              autoComplete="off"
              type="text"
              id="name"
              onChange={onChangeName}
              name="name"
              placeholder=""
              required=""
              value={gifter.name}
            />
          </div>
        </div>

        <div className="form__control">
          <label className="lbl" htmlFor="name">
            Email Address:
          </label>
          <div className="input">
            <input
              autoComplete="off"
              type="text"
              id="email"
              onChange={onChangeEmail}
              name="email"
              placeholder=""
              required=""
              value={gifter.email_id}
            />
          </div>
        </div>

        {forWho == "corporate" && (
          <div className="form__control">
            <label className="lbl" htmlFor="name">
              Company Name:
            </label>
            <div className="input">
              <input
                autoComplete="off"
                type="text"
                id="company_name"
                onChange={onChangeComapnyName}
                name="company_name"
                placeholder=""
                required=""
                value={gifter.company_name}
              />
            </div>
          </div>
        )}
        <div className="form__control">
          <label className="lbl" htmlFor="phoneNumber">
            Enter Mobile Number:
          </label>
          <div className="input">
            <PhoneInput
              placeholder="Enter phone number"
              disabled={showOtpField}
              limitMaxLength={true}
              value={mobile_no}
              defaultCountry="IN"
              onChange={onChangeNumber}
              error={
                mobile_no
                  ? isPossiblePhoneNumber(mobile_no)
                    ? undefined
                    : "Invalid phone number"
                  : "Phone number required"
              }
            />
            {showOtpField && (
              <div className="input__icon">
                <i className="tssi-edit" onClick={onEditNumber}></i>
              </div>
            )}
          </div>
        </div>
        {showOtpField && (
          <div className="form__control">
            <label className="lbl" htmlFor="enterOTP">
              Enter OTP:
              <ResendOtp onResendPress={onSendOTP} />
            </label>
            <div className="input">
              <input
                ref={otpRef}
                id="enterOTP"
                name="enterOTP"
                placeholder=""
                value={otp}
                required=""
                onChange={onOTPChange}
                type="number"
              />
              <div className="errMsg" hidden={!invalidOTP}>
                Invalid OTP
              </div>
            </div>
          </div>
        )}
        <div className="form__control">
          <div className="form__cta">
            <CtaButton
              btnCls="btn btn__primary"
              iconCls=""
              btnText={showOtpField ? "Proceed" : "Request OTP"}
              btnState={
                loading ||
                !validateEmail(gifter.email_id) ||
                isEmpty(gifter.name)
              }
              onClickProp={showOtpField ? onVerifyOTP : onSendOTP}
            />
          </div>
        </div>
        <div className="form__control">
          <div className="form__note">
            By continuing you agree to our <br />
            <a
              className="btn btn__link"
              href="https://thesmilingsouls.com/terms-conditions/"
              target="blank"
            >
              Terms & Conditions
            </a>
            and
            <a
              className="btn btn__link"
              href="https://thesmilingsouls.com/privacy-policy/"
              target="blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyYourself;
