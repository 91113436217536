const CHANGE_MEMBERSHIP_TYPE = "CHANGE_MEMBERSHIP_TYPE";
const CHANGE_PERSON_NAME = "CHANGE_PERSON_NAME";
const CHANGE_PERSON_MOBILE = "CHANGE_PERSON_MOBILE";
const CHANGE_PERSON_DOB = "CHANGE_PERSON_DOB";
const CHANGE_PERSON_GENDER = "CHANGE_PERSON_GENDER";
const CHANGE_PERSON_PINCODE = "CHANGE_PERSON_PINCODE";
const CHANGE_TAB = "CHANGE_TAB";
const CHANGE_NAME = "CHANGE_NAME";
const CHANGE_COMPANY_NAME = "CHANGE_COMPANY_NAME";
const CHANGE_EMAIL = "CHANGE_EMAIL";
const GIFT_STORAGE = "GIFT_STORAGE";
const USERS_TO_BE_GIFTED = "USERS_TO_BE_GIFTED";

const CHANGE_MOBILE_NO = "CHANGE_MOBILE_NO";
const COMPLETED_STEPS = "COMPLETED_STEPS";
const RESET_REDUCER = "RESET_REDUCER";

const membershipTypes = [
  { label: "Gold", value: "gold", price: 1100 },
  { label: "Diamond", value: "diamond", price: 2100 },
  { label: "Platinum", value: "platinum", price: 4100 },
];

const VALID_GENDERS = ["male", "female", "other", ""];
const VALID_MEMBERSHIP_TYPES = ["gold", "platinum", "diamond"];

export {
  CHANGE_TAB,
  CHANGE_NAME,
  GIFT_STORAGE,
  CHANGE_EMAIL,
  VALID_GENDERS,
  RESET_REDUCER,
  membershipTypes,
  COMPLETED_STEPS,
  CHANGE_MOBILE_NO,
  CHANGE_PERSON_DOB,
  USERS_TO_BE_GIFTED,
  CHANGE_PERSON_NAME,
  CHANGE_COMPANY_NAME,
  CHANGE_PERSON_GENDER,
  CHANGE_PERSON_MOBILE,
  CHANGE_PERSON_PINCODE,
  CHANGE_MEMBERSHIP_TYPE,
  VALID_MEMBERSHIP_TYPES,
};
