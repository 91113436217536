import React from "react";
const menu = [
  {
    sectionId: "/",
    label: "Home",
  },
  { sectionId: "/#section1", label: "About Us" },
  { sectionId: "/#section2", label: "Memberships" },
  // { sectionId: "#section3", label: "Testimonials" },
  { sectionId: "/#section4", label: "FAQs" },
];

const NavMenu = ({ isMenuItemsHide }) => {
  return (
    <>
      <nav className="navbar">
        {!isMenuItemsHide && (
          <ul>
            {menu.map((item, index) => (
              <li key={index}>
                <a
                  href={`${item.sectionId}`}
                  target={item.target}
                  data-to-scrollspy-id={item.sectionId}
                >
                  <span>{item.label}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
      </nav>
    </>
  );
};

export default NavMenu;
