import React, { useRef, useEffect, useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { Card, CtaButton } from "../../ReusableComponents";
// import "./summary-item.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { membershipTypes } from "../../context/Constants";
import { useAppReducer } from "../../context";
import { isEmpty } from "lodash";
import axios from "axios";
import PayU from "./PayU";

const PayNow = () => {
  const [params, setSearchParams] = useSearchParams();
  const typeOfMembership = params.get("type");
  const forWho = params.get("for") || "individual";
  const [membership, setMembership] = useState("");
  const [total, setTotal] = useState("");
  const [membershipCounts, setMembershipCounts] = useState([]);
  const {
    personPincode,
    receivers,
    personName,
    personMobile,
    personGender,
    personDOB,
    gifter,
  } = useAppReducer();
  const [hash, setHash] = useState("");
  const [txnId, setTxnId] = useState("");
  const formRef = useRef(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (hash && txnId) {
      formRef.current.submit();
    }
  }, [hash, txnId, formRef]);

  useEffect(() => {
    if (forWho === "individual") {
      const membership = membershipTypes.find(
        (v) => v.value === typeOfMembership
      );
      setMembership(membership);
      setTotal({
        actualPrice: membership.price,
        discountedPrice: membership.price,
        discountPercent: 0,
      });
    } else {
      const goldUnitPrice = membershipTypes.find(
        (v) => v.value === "gold"
      ).price;
      const platinumUnitPrice = membershipTypes.find(
        (v) => v.value === "platinum"
      ).price;
      const diamondUnitPrice = membershipTypes.find(
        (v) => v.value === "diamond"
      ).price;

      const gold = receivers.filter((v) => v["Membership Type"] === "gold");
      const platinum = receivers.filter(
        (v) => v["Membership Type"] === "platinum"
      );
      const diamond = receivers.filter(
        (v) => v["Membership Type"] === "diamond"
      );

      const obj = [];

      const goldPrice = gold.length * goldUnitPrice;
      const platinumPrice = platinum.length * platinumUnitPrice;
      const diamondPrice = diamond.length * diamondUnitPrice;

      if (goldPrice) {
        obj.push({ totalPrice: goldPrice, type: "Gold", count: gold.length });
      }

      if (platinumPrice) {
        obj.push({
          totalPrice: platinumPrice,
          type: "Platinum",
          count: platinum.length,
        });
      }

      if (diamondPrice) {
        obj.push({
          totalPrice: diamondPrice,
          type: "Diamond",
          count: diamond.length,
        });
      }
      const actualPrice = goldPrice + platinumPrice + diamondPrice;
      let discountedPrice = actualPrice;
      let discountPercent = 0;

      if (receivers.length >= 10 && receivers.length <= 24) {
        discountedPrice = actualPrice * 0.9;
        discountPercent = 10;
      } else if (receivers.length >= 25 && receivers.length <= 99) {
        discountedPrice = actualPrice * 0.83;
        discountPercent = 17;
      } else if (receivers.length >= 100) {
        discountedPrice = actualPrice * 0.75;
        discountPercent = 25;
      }

      setTotal({
        actualPrice,
        discountedPrice: discountedPrice.toFixed(0),
        discountPercent,
      });
      setMembershipCounts(obj);
    }
  }, [typeOfMembership, receivers, forWho]);

  const onMembershipChange = useCallback((event) => {
    setSearchParams({ type: event.target.value, for: params.get("for") });
  }, []);

  const onHandlePayNow = useCallback(
    (event) => {
      event.preventDefault();
      const onGetOrderId = async () => {
        try {
          let receiversData = [];
          if (forWho == "individual") {
            receiversData = [
              {
                name: personName,
                mobile_no: personMobile.replace("+", ""),
                gender: personGender,
                membership_type: typeOfMembership,
                dob: personDOB,
                pincode: personPincode,
              },
            ];
          } else {
            const mappedData = receivers.map((v) => {
              return {
                name: v["Name"],
                mobile_no: v["Mobile No."],
                gender: v["Gender"],
                membership_type: v["Membership Type"],
                dob: v["DOB"] || "",
                pincode: v["Pincode"],
              };
            });
            receiversData = mappedData;
          }
          gifter.mobile_no = gifter.mobile_no.replace("+", "");
          const body = {
            gifter,
            gift_type: forWho,
            actual_price: total.actualPrice,
            total_discount: total.actualPrice - total.discountedPrice,
            total_price: total.discountedPrice,
            receivers: receiversData,
          };

          const { data } = await axios.post(
            "https://thesmilingsouls.com/custom/index.php/api/v1/gift/create-order",
            body
          );
          if (data.code == 200) {
            const { hash, transaction_id } = data?.data;
            setHash(hash);
            setTxnId(transaction_id);
          }
        } catch (error) {}
        setLoading(false);
      };
      if (!loading) {
        setLoading(true);
        onGetOrderId();
      }
    },
    [receivers, formRef, typeOfMembership, forWho, total, gifter]
  );

  const Item = ({ totalPrice, type, count }) => {
    return (
      <>
        <div className="paymentCart__list">
          <ul>
            <li>
              <div className="paymentCart__list-grid">
                <div className="paymentCart__list-lhs">
                  <div className="paymentCart__list-count">
                    <div className="paymentCart__list-countNumber">{count}</div>
                    <div className="paymentCart__list-countLbl">{type}</div>
                  </div>
                  <div className="paymentCart__list-text">
                    Membership
                    {/* {item.lable}
          {item.isEdit && (
            <a className="btn btn__link" href="#">
              (Edit)
            </a>
          )} */}
                  </div>
                </div>
                <div className="paymentCart__list-rhs">
                  <div className="paymentCart__list-price">
                    <i className="tssi-rupee"></i>
                    {totalPrice}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  };
  return (
    <div className="form__wrap form__wrap--paymentCart">
      <div className="form__wrap-lhs">
        <div className="card__swiper">
          <Swiper
            pagination={{ clickable: false }}
            modules={[Pagination, Autoplay]}
            slidesPerView={1}
            spaceBetween={16}
            loop={false}
            autoplay={false}
            className="mySwiper"
          >
            {(typeOfMembership === "gold" || forWho === "corporate") && (
              <SwiperSlide>
                <Card
                  cardType="gold"
                  cardThumb="gold-text.svg"
                  cardTitle="51 Smiles"
                  cardCaption="Validity - 3 Months"
                />
              </SwiperSlide>
            )}
            {(typeOfMembership === "diamond" || forWho === "corporate") && (
              <SwiperSlide>
                <Card
                  cardType="diamond"
                  cardThumb="diamond-text.svg"
                  cardTitle="101 Smiles"
                  cardCaption="Validity - 6 Months"
                />
              </SwiperSlide>
            )}

            {(typeOfMembership === "platinum" || forWho === "corporate") && (
              <SwiperSlide>
                <Card
                  cardType="platinum"
                  cardThumb="platinum-text.svg"
                  cardTitle="251 Smiles"
                  cardCaption="Validity - 12 Months"
                />
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
      <div className="form__wrap-rhs">
        <div className="paymentCart__box">
          <h2 className="form__title">
            <span>Summary details</span>
          </h2>
          <div className="paymentCart__list">
            {forWho === "corporate" && !isEmpty(membershipCounts) && (
              <>
                {membershipCounts.map((v) => (
                  <Item key={v.type} {...v} />
                ))}
              </>
            )}
            {forWho === "individual" && (
              <div className="input">
                <select value={membership.value} onChange={onMembershipChange}>
                  {membershipTypes.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <Item
                  count={1}
                  totalPrice={membership.price}
                  type={membership.value}
                />
              </div>
            )}
          </div>

          {total.actualPrice && total.actualPrice > total.discountedPrice && (
            <div className="paymentCart__discount">
              <div className="paymentCart__discount-lbl">
                <span>
                  Discount <em>({total.discountPercent}%)</em>
                </span>
              </div>
              <div className="paymentCart__discount-sum">
                <i class="tssi-rupee"></i>
                {total.actualPrice - total.discountedPrice}
              </div>
            </div>
          )}
          <div className="paymentCart__total">
            <div className="paymentCart__total-lbl">
              <span>Total</span>
            </div>
            <div className="paymentCart__total-sum">
              <i class="tssi-rupee"></i>
              {total.discountedPrice}
            </div>
          </div>
          <div className="paymentCart__cta">
            <button
              className="btn btn__primary"
              type="button"
              onClick={onHandlePayNow}
            >
              <span>Pay Now</span>
            </button>
          </div>
          {/* <div className="paymentCart__cta">
            <CtaButton
              btnCls="btn btn__primary"
              iconCls=""
              btnText="Pay Now"
              onClick={onHandlePayNow}
            />
          </div> */}
          {/* <div className="disclaimer">
              <div>#Price is inclusive of GST*</div>
              <div>*Valid during the membership period only</div>
              <div># Smiles would be used to attend Fun & Learn Sessions</div>
            </div> */}

          <form
            action="https://secure.payu.in/_payment"
            method="post"
            ref={formRef}
          >
            <input type="hidden" name="key" value="qP7RxA" />
            <input type="hidden" name="txnid" value={txnId} />
            <input type="hidden" name="productinfo" value={forWho} />

            <input type="hidden" name="amount" value={total.discountedPrice} />
            <input type="hidden" name="email" value={gifter.email_id} />
            <input type="hidden" name="firstname" value={gifter.name} />
            <input
              type="hidden"
              name="surl"
              value="https://thesmilingsouls.com/custom/index.php/api/v1/gift/order-payment"
            />
            <input
              type="hidden"
              name="furl"
              value="https://thesmilingsouls.com/custom/index.php/api/v1/gift/order-payment"
            />
            <input type="hidden" name="phone" value={gifter.mobile_no} />
            <input type="hidden" name="hash" value={hash} />
          </form>
          <div className="paymentCart__note">
            <div className="form__note">
              <a className="btn btn__link" href>
                #Price is inclusive of GST*
              </a>
              <br />
              * Valid during the membership period only <br /># Smiles would be
              used to attend Fun & Learn Sessions
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayNow;
