import React, { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import { AppProvider, ErrorToastProvider, LoaderProvider } from "../context";
import { PageLoader } from "../ReusableComponents";
import PurchaseMembership from "../Components/PurchaseMembership";
import SuccessfullyGifted from "../Components/SuccessfullyGifted";
import Failure from "../Components/Failure";
// import ProtectedRoute from "./ProtectedRoute";
// import routes from "./paths";

const App = lazy(() => import("../views/app"));
const Home = lazy(() => import("../views/home"));

const router = createBrowserRouter(
  [
    {
      element: <App />,
      path: "/",
      errorElement: <ErrorBoundary />,
      children: [
        {
          element: <Home />,
          index: true,
          errorElement: <ErrorBoundary />,
        },
        {
          Component: PurchaseMembership,
          path: "purchase-membership",
          errorElement: <ErrorBoundary />,
        },
        {
          element: <SuccessfullyGifted />,
          path: "/success",
          errorElement: <ErrorBoundary />,
        },
        {
          element: <Failure />,
          path: "/failure",
          errorElement: <ErrorBoundary />,
        },
      ],
    },
  ]
  // { basename: "/" }
);
function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <>error page come here...</>;
}

function RouteConfig() {
  return (
    <Suspense
      fallback={
        <div className="master__wrap">
          <PageLoader />
        </div>
      }
    >
      <LoaderProvider>
        <AppProvider>
          <ErrorToastProvider>
            <RouterProvider router={router} />
          </ErrorToastProvider>
        </AppProvider>
      </LoaderProvider>
    </Suspense>
  );
}

export default RouteConfig;
