import React from "react";
import { CtaButton } from "../../ReusableComponents/Buttons";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
const SuccessfullyGifted = () => {
  return (
    <>
      <section className="success__wrap">
        <div className="main__container">
          <div className="form__wrap form__wrap--gridWidth">
            <div className="success__box">
              <div className="success__thumb">
                <img
                  src={`${IMAGE_BASE_URL}/gift-truck.svg?v=${IMAGE_VERSION}`}
                  alt="successfully gifted thumb"
                  loading="lazy"
                />
              </div>
              <h1 className="success__title">
                <span>BLESSINGS COMING YOUR WAY!</span>
              </h1>
              <article className="success__caption">
                <p>
                  Congratulations! You have succesfully completed the gifting
                  transaction!
                </p>
              </article>

              <div className="success__cta">
                <a className="btn btn__link" href="/">Continue to Homepage</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SuccessfullyGifted;
