import React, { useEffect, useState, useCallback } from "react";
import "react-phone-number-input/style.css";
import VerifyYourself from "./VerifyYourself";
import AddGiftPage from "./AddGiftPage";
import AddCorporateGiftPage from "./AddCorporateGiftPage";
import PayNow from "./PayNow";
import { useAppDisptach, useAppReducer } from "../../context";
import { useSearchParams, unstable_useBlocker } from "react-router-dom";
import {
  CHANGE_TAB,
  RESET_REDUCER,
  GIFT_STORAGE,
} from "../../context/Constants";

const stepCount = [
  { serialNo: 1, label: "Verify", active: true },
  { serialNo: 2, label: "Details", active: false },
  { serialNo: 3, label: "Payment", active: false },
];

const PurchaseMembership = ({}) => {
  const dispatch = useAppDisptach();
  const state = useAppReducer();
  const activeTab = state.activeTab;
  const completedSteps = state.completedSteps;
  useEffect(() => {
    return () => {
      dispatch({ type: RESET_REDUCER });
    };
  }, []);

  // const blocker = unstable_useBlocker(true);

  // if (blocker.state === "blocked") {
  // let yes = window.confirm("Are you sure you want to leave?");
  // if (yes) {
  // blocker.proceed?.();
  // localStorage.setItem(GIFT_STORAGE, null);
  // } else {
  // blocker.reset?.();
  // }
  // }

  const [params] = useSearchParams();
  const typeOfMembership = params.get("for") || "individual";

  useEffect(() => {
    localStorage.setItem(GIFT_STORAGE, JSON.stringify(state));
  }, [state]);

  const onNextStep = useCallback(
    (step) => () => {
      dispatch({ type: CHANGE_TAB, value: step });
    },
    []
  );
  const onTabChange = useCallback(
    (step) => () => {
      dispatch({ type: CHANGE_TAB, value: step });
    },
    []
  );

  return (
    <>
      <section className="purMemship__wrap">
        <div className="main__container">
          <div className="purMemship__step">
            <ul>
              {stepCount.map((item, index) => (
                <li
                  key={index}
                  className={completedSteps.includes(index) ? "active" : ""}
                >
                  <div
                    className="purMemship__step-num"
                    style={{
                      cursor: completedSteps.includes(index) ? "pointer" : null,
                    }}
                  >
                    <span>{item.serialNo}</span>
                  </div>
                  <div className="purMemship__step-txt">
                    <span>{item.label}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {activeTab == 0 && <VerifyYourself onNextStep={onNextStep(1)} />}
          {activeTab == 1 && typeOfMembership == "individual" && (
            <AddGiftPage onNextStep={onNextStep(2)} />
          )}
          {activeTab == 1 && typeOfMembership == "corporate" && (
            <AddCorporateGiftPage onNextStep={onNextStep(2)} />
          )}
          {activeTab == 2 && <PayNow onNextStep={onNextStep(3)} />}
        </div>
      </section>
    </>
  );
};

export default PurchaseMembership;
