import React, { useState, useCallback } from "react";
import XLSX from "xlsx";

import {
  USERS_TO_BE_GIFTED,
  VALID_GENDERS,
  VALID_MEMBERSHIP_TYPES,
} from "../../context/Constants";
import { useAppDisptach, useAppReducer } from "../../context";
import DemoFile from "../../Common/TSS Corporate Gift Membership - Uploading Form.csv";
import { isEmpty } from "lodash";
const AddCorporateGiftPage = ({ onNextStep }) => {
  const [errors, setError] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const dispatch = useAppDisptach();
  const state = useAppReducer();

  const onShowMore = useCallback(() => {
    setShowMore((o) => !o);
  }, []);

  const onPickedFile = useCallback(async (event) => {
    setError(null);
    if (event.target.files.length === 0) return;

    const file = event.target.files.item(0);
    var reader = new FileReader();
    reader.onload = (e) => {
      let wb = XLSX.read(e.target.result);
      let users = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      let errors = [];
      users = users.filter((user) => {
        if (user["Name"] && user["Gender"] && user["Membership Type"]) {
          return true;
        }
        return false;
      });

      users = users.map((user, idx) => {
        idx = idx + 1;
        user["Gender"] = user["Gender"]
          ? user["Gender"].toLowerCase()
          : user["Gender"];
        user["Membership Type"] = user["Membership Type"]
          ? user["Membership Type"].toLowerCase()
          : user["Membership Type"];
        user["Name"] = user["Name"] ? user["Name"].toLowerCase() : user["Name"];
        user["Mobile No."] = user["Mobile No."]
          ? String(user["Mobile No."]).toLowerCase()
          : user["Mobile No."];
        if (
          !user["Mobile No."] &&
          !VALID_MEMBERSHIP_TYPES.includes(user["Membership Type"])
        ) {
          return false;
        }

        if (!VALID_GENDERS.includes(user["Gender"])) {
          errors.push("Not valid gender in line no. " + idx);
        }
        if (!VALID_MEMBERSHIP_TYPES.includes(user["Membership Type"])) {
          errors.push(
            user["Membership Type"] +
              " is not a valid membership in line no. " +
              idx
          );
        }
        if (!user["Name"]) {
          errors.push("Not a valid name in line no. " + idx);
        }
        if (
          user["Mobile No."] &&
          (user["Mobile No."].length === "0" ||
            user["Mobile No."].length < 10 ||
            user["Mobile No."].length > 14)
        ) {
          errors.push("Not a valid mobile no. in line no. " + idx);
        }
        return user;
      });

      if (!isEmpty(errors)) {
        setError(errors);
        dispatch({ type: USERS_TO_BE_GIFTED, value: [] });
        event.target.value = "";
        return;
      }

      dispatch({ type: USERS_TO_BE_GIFTED, value: users });
      onNextStep();
      event.target.value = "";
    };

    reader.readAsArrayBuffer(file);
  }, []);

  return (
    <div className="form__wrap form__wrap--gridWidth">
      <h2 className="form__title">
        <span>Add Gift person Details</span>
      </h2>
      <h3 className="form__titleSub">
        <span>Upload File</span>
      </h3>
      <div className="form-upload__wrap">
        <div className="form-upload__masking">
          <label htmlFor="fileInput">Upload File:</label>
          <input
            type="file"
            id="fileInput"
            name="fileInput"
            onChange={onPickedFile}
            accept=".xlsx, .xls, .csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>
        <div className="form-upload__box">
          <div className="form-upload__icon">
            <i className="tssi-download"></i>
          </div>
          <div className="form-upload__text">
            Drag and drop file here or <em>choose file</em>
          </div>
        </div>
      </div>
      <div className="form-upload__notes">
        <span>Support Format : .xlsx, .xls, .csv</span>
        <span>Maximum Size : 25 MB</span>
      </div>
      {!isEmpty(errors) && (
        <>
          <button onClick={onShowMore} className="show-more-button">
            {showMore ? "Show less" : " Show more"}
          </button>
          {showMore &&
            errors.map((v) => (
              <div key={v} className="error" style={{ color: "red" }}>
                {v}
              </div>
            ))}
          {!showMore && (
            <div className="error" style={{ color: "red" }}>
              {errors[0]}
            </div>
          )}
        </>
      )}
      <div className="sampleData__wrap">
        <div className="sampleData__box">
          <div className="sampleData__lhs">
            <div className="sampleData__title">
              <i className="tssi-pdf"></i> Upload Instruction
            </div>
            <div className="sampleData__caption">
              Download the file, Fill in the details, Validate the same & upload
              to proceed further.
            </div>
          </div>
          <div className="sampleData__rhs">
            <a
              className="btn btn__primary btn__primary--outline"
              href={DemoFile}
              download="TSS Corporate Gift Membership - Uploading Form.csv"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCorporateGiftPage;
