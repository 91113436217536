import { createContext, useContext, useReducer } from "react";

import {
  CHANGE_TAB,
  CHANGE_NAME,
  RESET_REDUCER,
  COMPLETED_STEPS,
  CHANGE_MOBILE_NO,
  CHANGE_EMAIL,
  CHANGE_COMPANY_NAME,
  CHANGE_PERSON_DOB,
  USERS_TO_BE_GIFTED,
  CHANGE_PERSON_NAME,
  CHANGE_PERSON_GENDER,
  CHANGE_PERSON_MOBILE,
  CHANGE_PERSON_PINCODE,
  CHANGE_MEMBERSHIP_TYPE,
} from "./Constants";

const initialState = {
  receivers: [],
  completedSteps: [0],
  gifter: {
    name: "",
    company_name: "",
    mobile_no: "",
    email_id: "",
  },
  activeTab: 0,
  membershipType: "",
  personName: "",
  personMobile: "",
  personDOB: "",
  personGender: "",
  personPincode: "",
};

const AppContext = createContext(null);
const DispatchContext = createContext(null);

const appReducer = (state, action) => {
  switch (action.type) {
    case USERS_TO_BE_GIFTED:
      return { ...state, receivers: action.value };
    case RESET_REDUCER:
      return initialState;
    case COMPLETED_STEPS:
      return { ...state, completedSteps: action.value };
    case CHANGE_MEMBERSHIP_TYPE:
      return { ...state, membershipType: action.value };
    case CHANGE_PERSON_NAME:
      return { ...state, personName: action.value };
    case CHANGE_PERSON_MOBILE:
      return { ...state, personMobile: action.value };
    case CHANGE_PERSON_DOB:
      return { ...state, personDOB: action.value };
    case CHANGE_PERSON_GENDER:
      return { ...state, personGender: action.value };
    case CHANGE_PERSON_PINCODE:
      return { ...state, personPincode: action.value };
    case CHANGE_TAB:
      let completedSteps = [];
      for (let i = 0; i <= action.value; i++) {
        completedSteps.push(i);
      }
      return { ...state, activeTab: action.value, completedSteps };
    case CHANGE_NAME:
      return { ...state, gifter: { ...state.gifter, name: action.value } };
    case CHANGE_MOBILE_NO:
      return { ...state, gifter: { ...state.gifter, mobile_no: action.value } };
    case CHANGE_EMAIL:
      return { ...state, gifter: { ...state.gifter, email_id: action.value } };
    case CHANGE_COMPANY_NAME:
      return {
        ...state,
        gifter: { ...state.gifter, company_name: action.value },
      };
    default:
    // throw Error("Unknown action: " + action.type);
  }
};

const AppProvider = ({ children }) => {
  const [appState, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={appState}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </AppContext.Provider>
  );
};

const useAppReducer = () => {
  return useContext(AppContext);
};

const useAppDisptach = () => {
  return useContext(DispatchContext);
};

export { AppProvider, useAppDisptach, useAppReducer };
