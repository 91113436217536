import React, { useCallback, useRef, useEffect, useState } from "react";

const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const ResendOtp = ({ onResendPress }) => {
  const [seconds, setSeconds] = useState(300);

  useInterval(() => {
    if (seconds > 0) {
      setSeconds((old) => old - 1);
    }
  }, 1000);

  const second = String(seconds % 60).padStart(2, 0);
  const minute = String(Math.floor(seconds / 60)).padStart(2, 0);


  const resendOtpCallback = useCallback(() => {
    setSeconds(300);
    // onResendPress();
  }, [onResendPress]);

  return (
    <div>
      {seconds > 0 && <p>{minute}:{second}</p>}
      {seconds == 0 && (
        <button
          className="btn btn__link"
          href="foo"
          onClick={resendOtpCallback}
        >
          Resend OTP
        </button>
      )}
    </div>
  );
};

export default ResendOtp;
