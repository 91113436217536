import React from "react";
import { CtaButton } from "../../ReusableComponents/Buttons";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
import { Link } from "react-router-dom";
const SuccessfullyGifted = () => {
  return (
    <>
      <section className="success__wrap">
        <div className="main__container">
          <div className="form__wrap form__wrap--gridWidth">
            <div className="success__box">
              <div className="success__thumb">
                <img
                  src={`${IMAGE_BASE_URL}/error_icon.svg?v=${IMAGE_VERSION}`}
                  alt="successfully gifted thumb"
                  loading="lazy"
                  style={{ marginBottom: 50 }}
                />
              </div>
              <h1 className="success__title">
                <span>Payment Failed!</span>
              </h1>
              <article className="success__caption">
                <p>
                  We are sorry! There was some error while making the payment!
                </p>
              </article>

              <div className="success__cta">
                <Link to="/">
                  <CtaButton
                    btnCls="btn btn__link"
                    btnText="Please! Try Again"
                    btnState={false}
                    onClickProp={""}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SuccessfullyGifted;
