// import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Router from "./router";
import "./App.css";
function App() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  return (
    <>
      <div className="main">
        <Router />
      </div>
    </>
  );
}

export default App;
