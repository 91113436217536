import React, { useState } from "react";
import Navbar from "../Navbar";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
import { isMobile } from "../../Common/utils";
import { useEffect } from "react";
import { CtaButton } from "../../ReusableComponents";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [isActive, setIsActive] = useState(true);
  const [isHamburgerActive, setIsHamburgerActive] = useState(true);
  const [isHamburgerMenuActive, setIsHamburgerMenuActive] = useState(true);
  const [isStickyActive, setIsStickyActive] = useState(true);
  const location = useLocation();

  const { pathname } = location;

  function menuToggle() {
    setIsHamburgerActive((prev) => !prev);
    setIsHamburgerMenuActive((prev) => !prev);
  }

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".header__wrap");
    const scrollTop = window.scrollY;
    if (scrollTop >= 90) {
      header.classList.add("isSticky");
      setIsStickyActive(true);
    } else {
      header.classList.remove("isSticky");
      setIsStickyActive(false);
    }
  };
  return (
    <>
      <header
        className="header__wrap"
        onScroll={() => {
          setIsActive(isActive);
        }}
      >
        <div className="main__container">
          <div className="header__grid">
            <div className="header__logo">
              <a href="https://thesmilingsouls.com" target="_blank">
                <img
                  src={`${IMAGE_BASE_URL}/${
                    isStickyActive ? "ss-logo.png" : "ss-logo.png"
                  }?v=${IMAGE_VERSION}`}
                  alt="smiling soul"
                  loading="lazy"
                />
              </a>
            </div>

            <div
              className={`header__menu ${menuToggle ? "active" : ""}`}
              onClick={menuToggle}
            >
              {isMobile() ? (
                isHamburgerMenuActive ? (
                  ""
                ) : (
                  <Navbar />
                )
              ) : (
                <Navbar />
              )}
            </div>

            <div className="header__cta">
              <a data-to-scrollspy-id={"#section2"} href={"#section2"}>
                <CtaButton
                  onClickProp={() => {}}
                  btnCls="btn btn__primary"
                  iconCls="tssi-gift"
                  btnText="Gift Now"
                  btnState={false}
                />
              </a>
            </div>
            {isMobile() && (
              <div className={`header__hamburger `} onClick={menuToggle}>
                <div className="header__hamburger-icon">
                  {isHamburgerActive ? (
                    <i className="tssi-hamburger"></i>
                  ) : (
                    <i className="tssi-close"></i>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
